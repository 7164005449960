html,
body {
  height: 100vh;
  margin: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-flow: column;
}
