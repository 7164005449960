:root {
  --outline-color: lightgrey;
  --chore-control-width: 250px;
  --day-column-width: 100px;
  --chart-total-width: calc(
    var(--chore-control-width) + (7 * var(--day-column-width))
  );
}

.chart {
  margin: auto;
  margin-top: 0;
  justify-content: center;
  display: grid;
  width: var(--chart-total-width);
  grid-template-columns: var(--chore-control-width) repeat(
      7,
      var(--day-column-width)
    );
}

.choreLabel {
  width: 100%;
  text-align: left;
  padding: 8px;
}

.choreBlock {
  border-right: solid 1px var(--outline-color);
  border-bottom: solid 1px var(--outline-color);
  padding: 4px;
}
